import React from "react";
import { PiGraduationCapBold } from "react-icons/pi";
import { ImBook } from "react-icons/im";
import { TbRulerMeasure } from "react-icons/tb";

const AboutUs = () => {
  return (
    <div className="max-w-[1400px] h-[727px] md:h-[627px] w-full m-auto relative group py-14 z-40">
      <div
        className="w-full h-full bg-center bg-cover duration-500"
        style={{
          backgroundImage: 'url("images/about.png")',
        }}
      >
        <div className="w-full h-full bg-black bg-opacity-75 py-5 px-3 md:px-14">
          <div className="space-y-10 md:space-y-14">
            <h3 className="font-bold text-white/90 text-4xl tracking-wide">
              We Offer |
            </h3>
            <div className="w-full md:w-1/2 space-y-10">
              <div className="flex items-center space-x-10">
                <PiGraduationCapBold className="font-bold text-8xl text-white" />
                <div className="space-y-3 text-white">
                  <h4 className="font-bold text-3xl tracking-widest">
                  Best NEET JEE Instructor
                  </h4>
                  <p className="tracking-wider">
                  With the team of Neet JEE EXPERTS KG GURANTEE THE BEST RESULT FOR NEET JEE exams with well-designed courses.

                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-10">
                <ImBook className="font-bold text-7xl text-white mx-2" />
                <div className="space-y-3 text-white">
                  <h4 className="font-bold text-3xl tracking-widest">
                  NEET JEE -Study Material and Mock Test

                  </h4>
                  <p className="tracking-wider">
                  Neet JEE  question bank and test series with online jee content is provided and accessed anytime for practice.

                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-10">
                <TbRulerMeasure className="font-bold text-8xl text-white ml-2" />
                <div className="space-y-3 text-white">
                  <h4 className="font-bold text-3xl tracking-widest">
                  Kota guru NEET JEE TOOLS-
                  </h4>
                  <p className="tracking-wider">
                  Blend with offline JEE CET classes with online support for  mock test
Providing NEET cet coaching offline and online practice paper/dpp topic wise with many exclusive features.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
