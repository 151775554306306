import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Heading from "../Helpers/Heading";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="max-w-[1400px] h-[327px] w-full m-auto relative group z-40">
        <div
          className="w-full h-full bg-center bg-cover duration-500"
          style={{
            backgroundImage: 'url("images/bg-image.png")',
          }}
        >
          <div className="w-full h-full bg-black bg-opacity-75 py-5 px-5 md:px-14 flex items-center">
            <div className="space-y-14">
              <Link
                to="/"
                className="font-bold text-white/90 text-2xl tracking-wide"
              >
                {"Home >"}
              </Link>
              <h3 className="font-bold text-white/90 text-6xl tracking-wide">
                Contact |
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-5 md:px-14 py-14 space-y-10">
        <Heading heading={"Contact Us"} />
        <div className="w-full md:flex justify-between space-y-14 md:space-y-0">
          <div className="w-full md:w-2/5 space-y-5">
            <div className="w-full space-y-3">
              <h4 className="font-medium text-2xl text-slate-700 tracking-wider">
                Connect With Us
              </h4>
              <p className="font-medium text-base text-slate-700 tracking-wider">
                +91 8884450707
              </p>
              <p className="font-medium text-base text-slate-700 tracking-wider">
              purnendubiotech@gmail.com
              </p>
            </div>
            <div className="w-full space-y-3">
              <h4 className="font-medium text-2xl text-slate-700 tracking-wider">
                Our Address
              </h4>
              <p className="font-medium text-base text-slate-700 tracking-wider">
              Jpgw Abhigree main road Bangalore 90
              </p>
            </div>
          </div>
          <div className="w-full md:w-2/5 space-y-5 font-medium text-base text-slate-700 tracking-wider">
            <h4 className="font-medium text-2xl text-slate-700 tracking-wider">
              Find Us
            </h4>
            <iframe
              src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=ambabhawani road bangalore&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              
              
      
              title="This is a unique title"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-2xl shadow shadow-slate-500 w-[350px] md:w-[400px] h-[200px]"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
