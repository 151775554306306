import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Heading from "../Helpers/Heading";

const data = [
  {
    id: 1,
    img: "gallery/happy.jpg",
    text: "Happy Learning",
  },
  {
    id: 2,
    img: "gallery/happy1.jpg",
    text: "Happy Learning",
     },
  {
    id: 3,
    img: "gallery/happy2.jpg",
    text: "Happy Learning",
  },
  {
    id: 4,
    img: "gallery/happy3.jpg",
    text: "Happy Learning",
  },
  {
    id: 5,
    img: "gallery/happy4.jpg",
    text: "Happy Learning",
  },
  {
    id: 6,
    img: "gallery/happy5.jpg",
    text: "Happy Learning",
  },
  {
    id: 7,
    img: "gallery/happy.jpg",
    text: "Happy Learning",
  },
  {
    id: 8,
    img: "gallery/happy.jpg",
    text: "Happy Learning",
  },
  {
    id: 9,
    img: "gallery/happy.jpg",
    text: "Happy Learning",
  },
  {
    id: 10,
    img: "gallery/happy.jpg",
    text: "Happy Learning",
  },
  {
    id: 11,
    img: "gallery/happy.jpg",
    text: "Happy Learning",
  },
  {
    id: 12,
    img: "gallery/happy.jpg",
    text: "Happy Learning",
  },
];

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="max-w-[1400px] h-[327px] w-full m-auto relative group z-40">
        <div
          className="w-full h-full bg-center bg-cover duration-500"
          style={{
            backgroundImage: 'url("images/bg-image.png")',
          }}
        >
          <div className="w-full h-full bg-black bg-opacity-75 py-5 px-5 md:px-14 flex items-center">
            <div className="space-y-14">
              <Link
                to="/"
                className="font-bold text-white/90 text-2xl tracking-wide"
              >
                {"Home >"}
              </Link>
              <h3 className="font-bold text-white/90 text-6xl tracking-wide">
                Gallery |
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-5 md:px-14 py-14 space-y-10">
        <Heading heading={"Our Memories"} />
        <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-8">
          {data.map((item) => {
            return (
              <div
                className="space-y-3 font-bold text-lg tracking-wider"
                key={item.id}
              >
                <img
                  src={item.img}
                  alt="..."
                  style={{width:'720px',height:'300px'}}
                  className="rounded-2xl hover:scale-125 duration-500"
                />
                <p>{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Gallery;
